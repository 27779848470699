body {
  margin: 0;
  /* min-width: 1280px; */
  padding: 0;
  color: #111;
  font-family: 'Inter';
  background-color: #F3F5F6 !important;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.Wistia-logo {
  animation: Wistia-logo-spin infinite 10s linear;
  height: 60px;
  padding-bottom: 10px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.wistia-embed-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
}

.glitch-link {
  color: #54bbff;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes Wistia-logo-spin {
  from { transform: rotate3d(100, -100, 100, 0deg); }
  to { transform: rotate3d(-100, 100, -100, 360deg); }
}


a {
  text-decoration: none !important;
  cursor: pointer;
  color: #0070f3;
}
a:hover {
  color: #0366d6;
}
h2 {
  color: #333;
}
.question {
  font-size: 28px;
  color: #1f2933;
  text-align: left;
  font-family: 'Inter';
  font-weight: 300;
  margin: 12px 0;
}
.subquestion {
  padding-left: 0px !important;
  font-size: 24px;
  color: #1f2933;
  text-align: left;
  font-family: 'Inter';
  font-weight: 300;
  padding: 0 0 8px 0 !important;
}
.subfieldheader {
  font-size: 16px;
  color: #1f2933;
  text-align: left;
  font-family: 'Inter';
  font-weight: 300;
  padding: 0 8px 8px 8px;
}
.address {
  padding: 4px 12px;
}
.questionsection {
  padding-left: 64px !important;
  padding: 16px 64px;
}
.multiquestionsection {
  padding: 16px 0;
}
.subquestionsection {
  padding: 0 0;
  transition: background-color 50ms ease-out;
}
.subquestionsectionhoverable, .rowhoverableactive {
  background-color: #DCE6EF;
  cursor: pointer;
}
.questionheader {
  font-size: 24px;
  color: #1f2933;
  text-align: left;
  font-family: 'Inter';
  font-weight: 300;
}
.formtooltip, .fablabel {
  font-size: 20px;
  color: #1f2933;
  text-align: left;
  font-family: 'Inter';
  font-weight: 300;
  padding: 8px 0 0;
}
.fablabel {
  padding: 0 16px;
}
.tooltippadding {
  padding: 0 8px;
}
.field {
  font-size: 20px;
  font-weight: 300;
  color: #1f2933;
  font-family: 'Inter';
  padding: 8px 4px;
  font-style: normal;
  /* display: flex; */
}
.fieldsection {
  padding: 8px 0 0 0;
}
.othertextbox {
  width: 160px !important;
}
.previousbutton, .nextbutton, .completebutton {
  border-radius: 5px;
  padding: 12px 12px;
  font-size: 14px;
  box-shadow: none;
  border: none;
}
.previousbutton:hover {
  color: #1f2933 !important;
}
.nextbutton:hover, .completebutton:hover {
  color: white !important;
}
.previousbutton {
  background-color: lightgrey;
  color: #1f2933;
  font-family: 'Inter';
}
.nextbutton, .completebutton {
 background-color: #3E56A6;
 color: white;
 font-family: 'Inter';
}
label {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  width: 100%;
}
form {
  margin-bottom: 0.5rem;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input:disabled {
  /* Disable this for now since it doesn't affect autocompleted fields */
  /* background-color: #c0c0c0 !important; */
}
button:disabled {
  background-color: #c0c0c0 !important;
}

input,
textarea {
  border-color: #1f2933;
  font-family: monospace;
  flex: 1 1 0%;
  margin-left: 0.5rem;
  box-shadow: none;
  color: #1f2933;
  background-color: transparent;
  border: 1px solid #d8d8d8;
  font-size: 16px;
  font-family: 'Inter';
  border-radius: 5px;
  outline: 0px;
  padding: 10px 25px;
}
input[type=textbox], textarea {
  border-color: #1f2933;
  max-width: 100%;
}
input[flag=half] {
  border-color: #1f2933;
  max-width: 45%;
  margin-left: 0px;
}
.fabbutton {
  margin: 24px 64px 0;
  cursor: pointer;
  user-select: none;
}
.fab {
  border-radius: 50%;
  background-color: #3E56A6;
  transition: background-color 0.1s ease;
  color: transparent;
  width: 48px;
  height: 48px;
  display: inline-flex;
}
.fab:hover {
  background-color: rgb(16, 113, 204);
}
.fab:active {
  background-color: rgb(15, 102, 184);
}
.addressclustersection {
  max-width: 25%;
}
input[flag=twothirds] {
  max-width: 65%;
}
input[flag=other], input[flag=full], textarea {
  width: initial !important;
}
textarea[flag=flex], textarea[flag=resize] {
  width: 100% !important;
  resize: none;
  overflow: auto !important;
}
textarea[flag=resize] {
  resize: vertical;
}
textarea, input[flag=flex] {
  margin-left: 0px;
  font-size: 14px;
  font-weight: 300;
  padding: 4px;
  display: flex;
  flex: 1 1 auto;
  border: none;
  min-width: 0;
  width: 100%;
}
input[type=checkbox] {
  margin-top: auto;
  margin-bottom: auto;
  flex: initial;
}
input[type=radio] {
  margin-top: auto;
  margin-bottom: auto;
  flex: initial;
  height: 20px;
  width: 20px;
}
/*
Falling back on the default bootstrap button
button {
  display: block;
  margin-bottom: 0.5rem;
  color: #fff;
  border-radius: 5px;
  border: none;
  background-color: #1f2933;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  padding: 10px 25px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}
button:hover,
button:active {
  transform: translate3d(0px, -1px, 0px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}*/

.formik-error, .formik-error-vert {
  color: #D92C23;
  font-size: 12px;
  padding: 4px 8px;
}

.formik-error-vert {
  padding: 4px 0px;
}

/** Grid CSS */
.flex-grid {
  display: flex;
}

.flex-grid-cell {
  /* flex: 1 1; set in JSX w/ gridsize */
  flex-direction: column;
  border: 0.50px solid #1f2933;
  padding: 8px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-align: center;
}

.flex-grid-cell.flex-error-cell {
  border: 0.50px solid red;
}

@media only screen and (max-width: 960px) {
  body {
    background: white;
  }
}
/*
Falling back on the default bootstrap button
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}
*/

path.close-svg-path {
  stroke: #1f29337a;
  transition: stroke 75ms ease-out;
}
path.close-svg-path:hover {
  stroke: #1f2933;
}
path.close-svg-path:active {
  stroke: #141b22;
}

  .ant-spin  .ant-spin-text {

    font-size: larger !important;

    margin-top: 40px;

  }

  .covidlogo > svg > path {
    fill: #1F2933 !important;
  }

  .covidlogo > svg >rect {
    fill: #1F2933 !important;
  }

  .mynav-item {
    margin-left: 24px;
    font-weight: 400;
    color: #1F2933;
    font-size: 20px;
  }

/* .table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table {
    border-collapse: collapse !important;
  } */
